import React from "react"
import { StaticImage } from "gatsby-plugin-image"

/* This example requires Tailwind CSS v2.0+ */
import { BadgeCheckIcon } from '@heroicons/react/outline'

export default function Section3() {
  return (
    <div className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-emerald-600">
                  <BadgeCheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Un concept unique
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                G1 PGC, G2 AVP, G5… difficile de choisir l’étude de sol qu’il vous faut.
                </p>
                <p className="mt-4 text-lg text-gray-500">
                Géotechnique Maison est votre meilleur allié pour trouver un bureau d’étude de sol près de chez vous et au meilleur prix.
                </p>
                <p className="mt-4 text-lg text-gray-500">
                Nous sommes le site de référence de la mise en relation entre des particuliers et des géotechniciens.
                </p>
                <p className="mt-4 text-lg text-gray-500">
                Notre service est entièrement gratuit pour les non-professionnels.
                </p>
                <div className="mt-6">
                  <a
                    href="/etude-sol/devis/"
                    className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700"
                  >
                    Parlez-nous de votre projet
                  </a>
                </div>
              </div>
            </div>
            
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <a
                    href="/bureau/region/"
              >
                <StaticImage
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="../../images/carte-france.png" 
                    alt="Géotechnique france"
                  />
              </a>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

import React from "react"
import { ArrowsExpandIcon, ScaleIcon, ShieldCheckIcon, UsersIcon, BadgeCheckIcon } from '@heroicons/react/outline'

/* This example requires Tailwind CSS v2.0+ */
export default function Section4() {
  return (
    <div className="bg-gray-50 pt-12 sm:pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Quel est votre projet ?
          </h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
          Décrivez votre besoin et entrez en contact avec des bureaux d’études géotechniques qualifiés près de chez vous.
          </p>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            <a
              href="/etude-sol/devis/"
              className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700"
            >
              Parlez-nous de votre projet
            </a>
          </p>
        </div>
        <div className="mt-6">
         
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Gratuit</dt>
                  <dd className="torder-1 text-xl font-bold text-emerald-600"><BadgeCheckIcon className="h-14 mx-auto" aria-hidden="true" /></dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Proximité</dt>
                  <dd className="order-1 text-5xl font-extrabold text-emerald-600"><UsersIcon className="h-14 mx-auto" aria-hidden="true" /></dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Sur mesure</dt>
                  <dd className="order-1 text-5xl font-extrabold text-emerald-600"><ArrowsExpandIcon className="h-14 mx-auto" aria-hidden="true" /></dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Qualité</dt>
                  <dd className="order-1 text-5xl font-extrabold text-emerald-600"><ShieldCheckIcon className="h-14 mx-auto" aria-hidden="true" /></dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Indépendance</dt>
                  <dd className="order-1 text-5xl font-extrabold text-emerald-600"><ScaleIcon className="h-14 mx-auto" aria-hidden="true" /></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

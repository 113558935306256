import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Section2() {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          

            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <StaticImage
                  className="h-48 w-full object-cover"
                  src="../../images/vente-terrain-constructible.jpg" 
                  alt="Vente terrain constructible"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href="/bureau/type-etude-sol/etude-sol-g1/" className="block mt-2">
                    <h2 className="text-xl font-semibold text-gray-900">VENTE TERRAIN CONSTRUCTIBLE</h2>
                    <ul className="mt-3 text-base text-gray-500">
                      <li>Etude de sol</li>
                      <li>Loi Elan G1 PGC</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <StaticImage
                  className="h-48 w-full object-cover"
                  src="../../images/construction-extension.jpg" 
                  alt="construction extension"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href="/bureau/type-etude-sol/etude-sol-g2/" className="block mt-2">
                    <h2 className="text-xl font-semibold text-gray-900">CONSTRUCTION MAISON &amp; EXTENSION</h2>
                    <ul className="mt-3 text-base text-gray-500">
                      <li>Etude de sol</li>
                      <li>G2 AVP préalable à construction</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <StaticImage
                  className="h-48 w-full object-cover"
                  src="../../images/sinistre-fissuration.jpg" 
                  alt="SInistre fissuration"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href="/bureau/type-etude-sol/etude-sol-g5/" className="block mt-2">
                    <h2 className="text-xl font-semibold text-gray-900">APRÈS UN SINISTRE FISSURATION</h2>
                    <ul className="mt-3 text-base text-gray-500">
                      <li>Etude de sol</li>
                      <li>Diagnostic G5</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <StaticImage
                  className="h-48 w-full object-cover"
                  src="../../images/assainissement.jpg" 
                  alt="assainissement"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href="/situation/assainissement-non-collectif/" className="block mt-2">
                    <h2 className="text-xl font-semibold text-gray-900">ASSAINISSEMENT NON COLLECTIF</h2>
                    <ul className="mt-3 text-base text-gray-500">
                      <li>Etude de sol ANC</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <StaticImage
                  className="h-48 w-full object-cover"
                  src="../../images/piscine.jpg" 
                  alt="Piscine géotechnique"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href="/" className="block mt-2">
                    <h2 className="text-xl font-semibold text-gray-900">PISCINE</h2>
                    <ul className="mt-3 text-base text-gray-500">
                      <li>Etude de sol piscine</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <StaticImage
                  className="h-48 w-full object-cover"
                  src="../../images/contact-geotechnique.jpg" 
                  alt="Contact géotechnique"
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href="/etude-sol/devis/" className="block mt-2">
                    <h2 className="text-xl font-semibold text-gray-900">VOTRE DEMANDE ICI</h2>
                    <ul className="mt-3 text-base text-gray-500">
                      <li>Expliquez-nous votre besoin</li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>




        </div>
      </div>
    </div>
  )
}

import React from "react"

import { StaticImage } from "gatsby-plugin-image"

/* This example requires Tailwind CSS v2.0+ */
import { ExternalLinkIcon } from '@heroicons/react/solid'

export default function Section1() {
  return (
    <div className="relative bg-slate-800">
      <div className="h-56 bg-emerald-500 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage
          className="w-full h-full object-cover"
          src="../../images/geotechnique-maison.jpg" 
          alt="Maison fissure"
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <p className="text-base font-semibold uppercase tracking-wider text-gray-300">geotechnique-maison.fr</p>
          <h1 className="mt-2 text-white text-2xl font-extrabold tracking-tight sm:text-3xl">Recevez jusqu’à 3 devis en étude de sol</h1>
          <p className="mt-3 text-lg text-gray-300">
          Simple, économique, pratique
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="/etude-sol/devis/"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Demandez un devis
                <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
